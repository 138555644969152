import { css,html } from 'lit-element';
import {render} from 'lit-html';

export const getUrlParams = (search = '') => {
  const hashes = search.slice(search.indexOf('?') + 1).split('&');
  return hashes.reduce((acc, hash) => {
    // eslint-disable-next-line
    const [key, val] = hash.split('=');
    return {
      ...acc,
      [key]: decodeURIComponent(val)
    };
  }, {});
};

export const date2DatePickerString = (datestring) => {
  let d = new Date(datestring);
  return d.getFullYear() + '-' + (1+d.getMonth()) + '-' + d.getDate();
}

export const currentDateString = () => {
  let now = new Date();
  return now.getFullYear() + '-' + (1+now.getMonth()) + '-' + now.getDate();
}

export const dayOfMonth = (d) =>
{
  return (d.getDate() < 10 ? '0' : '') + d.getDate();
}

export const monthOfYear = (d) =>
{
  return ((d.getMonth()+1) < 10 ? '0' : '') + (d.getMonth() + 1);
}

export const setupDatePicker = (datepicker) => {

  datepicker.i18n = {
    week: 'Woche',
    calendar: 'Kalender',
    clear: 'Löschen',
    today: 'Heute',
    cancel: 'Schließen',
    firstDayOfWeek: 1,
    monthNames:
      'Jänner_Februar_März_April_Mai_Juni_Juli_August_September_Oktober_November_Dezember'.split('_'),
    weekdays: 'Sonntag_Montag_Dienstag_Mittwoch_Donnerstag_Freitag_Samstag'.split('_'),
    weekdaysShort: 'So_Mo_Di_Mi_Do_Dr_Sa'.split('_'),
    formatDate: function(date) {
      return date.day + '.' + (1+date.month) + '.' + date.year;
    },
    formatTitle: function(monthName, fullYear) {
      return monthName + ' ' + fullYear;
    },
    /*
    parseDate: function(dateString) {
      const date = Sugar.Date.create(dateString);
      return {
        day: date.getDate(),
        month: date.getMonth(),
        year: date.getFullYear()
      };
    }*/
  };

}

// Renderer für Vaadin Select
export const selectionSpecialRenderer = (slist, txtAtt, valAtt) => {
  let templist = slist;
  return function(root) {
      // Check if there is a list-box generated with the previous renderer call to update its content instead of recreation
      if (root.firstChild) {
        return;
      }
      // create the <vaadin-list-box>
      const listBox = window.document.createElement('vaadin-list-box');
      // append 3 <vaadin-item> elements
      templist.forEach(function(s) {
        const item = window.document.createElement('vaadin-item');
        item.textContent = s[txtAtt];
        //item.setAttribute('label',s.RmsID);
        item.setAttribute('value', s[valAtt]);
        listBox.appendChild(item);
      });
      // update the content
      root.appendChild(listBox);
  }
}

export const newNullStringHeaderRenderer = (path, bezeichnung) => {
  return (root) => {
      render(
        html`
          <vaadin-grid-sorter path="${path}">${bezeichnung}</vaadin-grid-sorter>
          <vaadin-grid-filter path="${path}">
            <vaadin-text-field
              slot="filter"
              focus-target
              theme="small"
              @value-changed="${(e) => e.target.parentNode.value = e.detail.value}"
            ></vaadin-text-field>
          </vaadin-grid-filter>
        `,
        root
      );

  }
}

export const newNullStringRenderer = (propname) => {
  return (root, column, rowData) => {
    render(
      rowData.item[propname].Valid
      ? html`<div>${rowData.item[propname].String}</div>`
      : ''
      ,
      root
    );
  }
}

export const newNumberRenderer = (colname1,  bold) => {
  var styleCSS = '';
  if (bold) styleCSS="font-weight:bold";
  var numberFormatter = new Intl.NumberFormat('de-DE',{minimumFractionDigits: 2});
  return (root, column, rowData) => {
    render(
      html`
        <div style="${styleCSS}">${numberFormatter.format(
          (rowData.item[colname1].Valid ? rowData.item[colname1].Float64 : 0)
          )}</div>
      `,
      root
    );
  }
}

export const nullstring2String = (ns) => {
  if (ns && ns.Valid) return ns.String
  return '';
}

export const string2NullString = (str) => {
  if (str) {
    return {String: str, Valid: true};
  }
  return {String: '', Valid: false};
}

export const nullInt642Number = (ns) => {
  if (ns && ns.Valid) return ns.Int64
  return null;
}

export const number2NullInt64 = (num) => {
  if (num) {
    return { Int64: num, Valid: true};
  }
  return { Int64: 0, Valid: false};
}

export class FormValidator {

  constructor(frm) {
    this._form = frm;
  }

  _getValidatableElements() {
    return this._findElements(
        this._form, true /* ignoreName */, false /* skipSlots */);
  }

  _getSubmittableElements() {
    return this._findElements(
        this._form, false /* ignoreName */, false /* skipSlots */);
  }

  /**
   * Traverse the parent element to find and add all submittable nodes to
   * `submittable`.
   * @param  {!Node} parent The parent node
   * @param  {!boolean} ignoreName  Whether the name of the submittable nodes should be disregarded
   * @param  {!boolean} skipSlots  Whether to skip traversing of slot elements
   * @param  {!Array<!Node>=} submittable Reference to the array of submittables
   * @return {!Array<!Node>}
   * @private
   */
  _findElements(parent, ignoreName, skipSlots, submittable) {
    submittable = submittable || [];
    var nodes = parent.querySelectorAll('*');
    for (var i = 0; i < nodes.length; i++) {
      // An element is submittable if it is not disabled, and if it has a
      // name attribute.
      if (!skipSlots &&
          (nodes[i].localName === 'slot' || nodes[i].localName === 'content')) {
        this._searchSubmittableInSlot(submittable, nodes[i], ignoreName);
      } else {
        this._searchSubmittable(submittable, nodes[i], ignoreName);
      }
    }
    return submittable;
  }

  /**
   * Traverse the distributed nodes of a slot or content element
   * and add all submittable nodes to `submittable`.
   * @param  {!Array<!Node>} submittable Reference to the array of submittables
   * @param  {!Node} node The slot or content node
   * @param  {!boolean} ignoreName  Whether the name of the submittable nodes should be disregarded
   * @return {void}
   * @private
   */
  _searchSubmittableInSlot(submittable, node, ignoreName) {
    var assignedNodes = dom(node).getDistributedNodes();

    for (var i = 0; i < assignedNodes.length; i++) {
      if (assignedNodes[i].nodeType === Node.TEXT_NODE) {
        continue;
      }

      // Note: assignedNodes does not contain <slot> or <content> because
      // getDistributedNodes flattens the tree.
      this._searchSubmittable(submittable, assignedNodes[i], ignoreName);
      var nestedAssignedNodes = dom(assignedNodes[i]).querySelectorAll('*');
      for (var j = 0; j < nestedAssignedNodes.length; j++) {
        this._searchSubmittable(
            submittable, nestedAssignedNodes[j], ignoreName);
      }
    }
  }

  /**
   * Traverse the distributed nodes of a slot or content element
   * and add all submittable nodes to `submittable`.
   * @param  {!Array<!Node>} submittable Reference to the array of submittables
   * @param  {!Node} node The node to be
   * @param  {!boolean} ignoreName  Whether the name of the submittable nodes should be disregarded
   * @return {void}
   * @private
   */
  _searchSubmittable(submittable, node, ignoreName) {
    if (this._isSubmittable(node, ignoreName)) {
      submittable.push(node);
    } else if (node.root) {
      this._findElements(
          node.root, ignoreName, true /* skipSlots */, submittable);
    }
  }

  /**
   * An element is submittable if it is not disabled, and if it has a
   * 'name' attribute. If we ignore the name, check if is validatable.
   * This allows `_findElements` to decide if to explore an element's shadowRoot
   * or not: an element implementing `validate()` is considered validatable, and
   * we don't search for validatables in its shadowRoot.
   * @param {!Node} node
   * @param {!boolean} ignoreName
   * @return {boolean}
   * @private
   */
  _isSubmittable(node, ignoreName) {
    return (
        !node.disabled &&
        (ignoreName ? node.name || typeof node.validate === 'function' :
                      node.name));
  }

  validate() {
    // If you've called this before distribution happened, bail out.
    if (!this._form) {
      return false;
    }

    if (this._form.getAttribute('novalidate') === '')
      return true;

    // Start by making the form check the native elements it knows about.
    var valid = this._form.checkValidity();
    var elements = this._getValidatableElements();

    // Go through all the elements, and validate the custom ones.
    for (var el, i = 0; el = elements[i], i < elements.length; i++) {
        // This is weird to appease the compiler. We assume the custom element
        // has a validate() method, otherwise we can't check it.
        var validatable = (el);
        if (validatable.validate) {
          valid = !!validatable.validate() && valid;
        }
    }

    return valid;
  }

  serializeForm() {
    // Only elements that have a `name` and are not disabled are submittable.
    var elements = this._getSubmittableElements();
    var json = {};
    for (var i = 0; i < elements.length; i++) {
      var values = this._serializeElementValues(elements[i]);
      for (var v = 0; v < values.length; v++) {
        this._addSerializedElement(json, elements[i].name, values[v]);
      }
    }
    return json;
  }

  _serializeElementValues(element) {
    // We will assume that every custom element that needs to be serialized
    // has a `value` property, and it contains the correct value.
    // The only weird one is an element that implements
    // IronCheckedElementBehaviour, in which case like the native checkbox/radio
    // button, it's only used when checked. For native elements, from
    // https://www.w3.org/TR/html5/forms.html#the-form-element. Native
    // submittable elements: button, input, keygen, object, select, textarea;
    // 1. We will skip `keygen and `object` for this iteration, and deal with
    // them if they're actually required.
    // 2. <button> and <textarea> have a `value` property, so they behave like
    //    the custom elements.
    // 3. <select> can have multiple options selected, in which case its
    //    `value` is incorrect, and we must use the values of each of its
    //    `selectedOptions`
    // 4. <input> can have a whole bunch of behaviours, so it's handled
    // separately.
    // 5. Buttons are hard. The button that was clicked to submit the form
    //    is the one who's name/value gets sent to the server.
    var tag = element.tagName.toLowerCase();
    if (tag === 'button' ||
        (tag === 'input' &&
         (element.type === 'submit' || element.type === 'reset'))) {
      return [];
    }

    if (tag === 'select') {
      return this._serializeSelectValues(element);
    } else if (tag === 'input') {
      return this._serializeInputValues(element);
    } else {
      if (element['_hasIronCheckedElementBehavior'] && !element.checked)
        return [];
      return [element.value];
    }
  }

  _serializeSelectValues(element) {
    var values = [];

    // A <select multiple> has an array of options, some of which can be
    // selected.
    for (var i = 0; i < element.options.length; i++) {
      if (element.options[i].selected) {
        values.push(element.options[i].value)
      }
    }
    return values;
  }

  _serializeInputValues(element) {
    // Most of the inputs use their 'value' attribute, with the exception
    // of radio buttons, checkboxes and file.
    var type = element.type.toLowerCase();

    // Don't do anything for unchecked checkboxes/radio buttons.
    // Don't do anything for file, since that requires a different request.
    if (((type === 'checkbox' || type === 'radio') && !element.checked) ||
        type === 'file') {
      return [];
    }
    return [element.value];
  }

  _addSerializedElement(json, name, value) {
    // If the name doesn't exist, add it. Otherwise, serialize it to
    // an array,
    if (json[name] === undefined) {
      json[name] = value;
    } else {
      if (!Array.isArray(json[name])) {
        json[name] = [json[name]];
      }
      json[name].push(value);
    }
  }



}